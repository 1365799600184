(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/nav-menu/assets/javascripts/oddset-nav-menu.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/nav-menu/assets/javascripts/oddset-nav-menu.js');
'use strict';

const {
  Link,
  matchPath
} = ReactRouterDOM;
const {
  useContext
} = React;
const {
  urlMapping
} = svs.core;
const {
  NavContext
} = svs.oddset_kambi.components.nav_context;
const {
  ItemsMenu,
  IconItem
} = svs.ui.ReactItemsMenu;
const {
  MenuTabTypes
} = svs.oddset_kambi.components.common.constants;
const isSelfService = svs.utils.ns.isNamespace('svs.core.config.data.svsconfig.isSelfService') && svs.core.config.data.svsconfig.isSelfService;
const moduleBaseUrl = svs.core.data.baseUrl;
const isSelected = (route, activePathname) => Boolean(matchPath(activePathname, {
  path: "/".concat(route.path)
}));
const isAnySelected = (routes, activePathname) => routes.some(route => matchPath(activePathname, {
  path: "/".concat(route.path)
}));
const {
  navigateTo
} = svs.oddset_kambi.components.wapiIntegration;
const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
const onTabClick = (clickEvent, route, currentRoute) => {
  if (route.isPlayRoute) {
    if (!isLoggedIn && route.tabType === MenuTabTypes.MyBets) {
      svs.components.customer_login.login();
      return;
    }
    navigateTo("/".concat(route.path));
    if (currentRoute.isPlayRoute) {
      clickEvent.preventDefault();
    }
  }
};
const OddsetNavMenu = _ref => {
  let {
    playRoute,
    activeRoutes,
    renderMobileMenu
  } = _ref;
  const {
    activePathname,
    currentRoute
  } = useContext(NavContext);

  const visibleRoutes = activeRoutes.filter(tab => renderMobileMenu && tab.activeMobile && !isSelfService || !renderMobileMenu && tab.activeDesktop);
  return React.createElement(ItemsMenu, {
    branding: "oddset-tab",
    centered: true,
    className: "js-oddset-items oddset-nav".concat(renderMobileMenu ? ' oddset-nav--mobile' : ''),
    compact: renderMobileMenu
  }, React.createElement(IconItem, {
    alignment: renderMobileMenu ? 'vertical' : 'horizontal',
    className: "oddset-nav__link",
    Component: Link,
    "data-testid": "oddset-route",
    gutter: 1,
    href: "".concat(moduleBaseUrl, "/").concat(playRoute.path),
    iconName: playRoute.icon,
    isSelected: !isAnySelected(visibleRoutes, activePathname),
    key: playRoute.path,
    label: renderMobileMenu && playRoute.shortLabel || playRoute.label,
    onClick: event => onTabClick(event, playRoute, currentRoute),
    to: "/".concat(playRoute.path)
  }), visibleRoutes.map(route => {
    const target = "/".concat(route.path);
    const href = route.isExternalRoute ? "/".concat(route.path) : "".concat(moduleBaseUrl).concat(target);
    return React.createElement(IconItem, {
      alignment: renderMobileMenu ? 'vertical' : 'horizontal',
      className: "oddset-nav__link",
      Component: route.isExternalRoute ? 'a' : Link,
      "data-testid": "oddset-route",
      gutter: 1,
      href: href,
      iconName: route.icon ? route.icon : 'play-circle',
      isSelected: isSelected(route, activePathname),
      key: route.path,
      label: renderMobileMenu && route.shortLabel || route.label,
      onClick: event => onTabClick(event, route, currentRoute),
      to: target
    });
  }));
};
setGlobal('svs.oddset_kambi.components.nav_menu.OddsetNavMenu', OddsetNavMenu);

 })(window);